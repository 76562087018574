<template>
  <div>
    <v-alert
      v-show="formIsError"
      color="error"
      transition="fade-transition"
      type="error"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px"
    >
      Error getting data!
    </v-alert>

    <v-alert
      v-show="formIsSubmitted"
      transition="fade-transition"
      color="success"
      type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      Thank you! The form has been submitted
    </v-alert>

    <v-container v-if="formIsLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3 class="text-center">
            <v-progress-circular indeterminate class="ml-3" color="primary" />
            Getting data . . .
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-alert v-if="!formIsLoading && !viewable" type="warning">
      This is not currently available for viewing.
    </v-alert>

    <v-container v-if="!formIsLoading && viewable">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h1 class="text-center mb-5">Investor KYC</h1>
          <v-stepper v-model="step" vertical>
            <div
              v-for="(section, index) in filtered_sections"
              :key="section.id"
            >
              <v-stepper-step
                :complete="
                  section.title === 'Sophisticated Declaration'
                    ? it_has_selected_sophisticated_declaration()
                    : section.is_valid
                "
                :step="index + 1"
              >
                <p
                  v-if="section.title !== 'Sophisticated Declaration'"
                  :class="section.title === step ? 'mb-0 green--text' : 'mb-0'"
                >
                  {{ section.title }}
                </p>

                <p
                  v-if="section.title === 'Sophisticated Declaration'"
                  :class="section.title === step ? 'mb-0 green--text' : 'mb-0'"
                >
                  {{
                    application_type_is_individual
                      ? "Personal Declaration"
                      : "Corporation Declaration"
                  }}
                </p>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-content :step="section.title">
                <v-card color="grey darken-4" class="mb-10 pa-5">
                  <v-form v-model="section.is_valid">
                    <div v-for="question in section.frm_qs" :key="question.id">
                      <!-- questions that has no condition -->
                      <p
                        v-if="
                          !question.is_a_follow_up_question &&
                          question.title !==
                            'Individual Sophisticated Investor' &&
                          question.title !==
                            'Corporation Sophisticated Investor' &&
                          question.title !==
                            'Are you borrowing money to lend via mortgages?' &&
                          question.title !==
                            'If you invested into a mortgage, if you took a 100% loss, would you still be able to survive financially?' && //this has condition
                          question.title !==
                            'Will you be lending money using personal funds?' &&
                          question.title !==
                            'Will you be lending money out of a corporation you own / control?'
                        "
                        :class="question.type === null ? 'my-5' : 'mb-0'"
                        style="white-space: pre-line"
                      >
                        {{ question.title }}
                      </p>

                      <!-- questions that has condition -->
                      <div>
                        <!-- for individual -->
                        <p
                          v-if="
                            application_type_is_individual &&
                            !question.is_a_follow_up_question &&
                            (question.title ===
                              'Individual Sophisticated Investor' ||
                              question.title ===
                                'Are you borrowing money to lend via mortgages?' ||
                              question.title ===
                                'If you invested into a mortgage, if you took a 100% loss, would you still be able to survive financially?' ||
                              question.title ===
                                'Will you be lending money using personal funds?')
                          "
                          :class="question.type === null ? 'my-5' : 'mb-0'"
                          style="white-space: pre-line"
                        >
                          {{ question.title }}
                        </p>

                        <!-- for corporation -->
                        <p
                          v-if="
                            (!application_type_is_individual ||
                              application_type_is_also_corporation) &&
                            !question.is_a_follow_up_question &&
                            (question.title ===
                              'Corporation Sophisticated Investor' ||
                              question.title ===
                                'Will you be lending money out of a corporation you own / control?')
                          "
                          :class="question.type === null ? 'my-5' : 'mb-0'"
                          style="white-space: pre-line"
                        >
                          {{ question.title }}
                        </p>
                      </div>

                      <!-- fields that has no condition -->
                      <div>
                        <v-text-field
                          v-if="
                            question.type === 'short text' &&
                            !question.is_a_follow_up_question
                          "
                          v-model="question.answer"
                          :rules="[
                            question.title === 'Email'
                              ? rules.email
                              : rules.required,
                          ]"
                          class="mb-5"
                          hide-details="auto"
                          type="text"
                          dense
                          filled
                          outlined
                        />

                        <v-text-field
                          v-if="
                            question.type === 'number' &&
                            !question.is_a_follow_up_question
                          "
                          v-model="question.answer"
                          :rules="[
                            question.title === 'Phone Number'
                              ? rules.phone
                              : rules.required,
                          ]"
                          class="mb-5"
                          hide-details="auto"
                          type="number"
                          dense
                          filled
                          outlined
                        />

                        <v-text-field
                          v-if="
                            question.type === 'date' &&
                            !question.is_a_follow_up_question
                          "
                          v-model="question.answer"
                          :rules="[rules.required]"
                          class="mb-5"
                          hide-details="auto"
                          type="date"
                          dense
                          filled
                          outlined
                        />

                        <v-textarea
                          v-if="
                            question.type === 'long text' &&
                            !question.is_a_follow_up_question
                          "
                          v-model="question.answer"
                          :rules="[rules.required]"
                          class="mb-5"
                          hide-details="auto"
                          type="text"
                          auto-grow
                          dense
                          filled
                          outlined
                        />

                        <v-radio-group
                          v-if="
                            question.type === 'single choice' &&
                            !question.is_a_follow_up_question &&
                            question.title !==
                              'Are you borrowing money to lend via mortgages?' &&
                            question.title !==
                              'If you invested into a mortgage, if you took a 100% loss, would you still be able to survive financially?' && //this has condition
                            question.title !==
                              'Will you be lending money using personal funds?' &&
                            question.title !==
                              'Will you be lending money out of a corporation you own / control?'
                          "
                          v-model="question.answer"
                          @change="
                            question.title === 'Application type'
                              ? handleApplicationType(question.answer)
                              : ''
                          "
                          :rules="[rules.required]"
                          class="mb-5"
                          hide-details="auto"
                          dense
                        >
                          <v-radio
                            v-for="choice in question.frm_opts
                              ? question.frm_opts
                              : []"
                            :key="choice.id"
                            :label="choice.choice"
                            :value="choice.choice"
                          />
                        </v-radio-group>
                        <div
                          v-if="
                            question.type === 'multiple choice' &&
                            !question.is_a_follow_up_question &&
                            question.title !==
                              'Corporation Sophisticated Investor' &&
                            question.title !==
                              'Individual Sophisticated Investor'
                          "
                          class="mb-5"
                        >
                          <v-checkbox
                            v-model="question.selected_choices"
                            v-for="choice in question.frm_opts
                              ? question.frm_opts
                              : []"
                            :key="choice.id"
                            :label="choice.choice"
                            :value="choice.id"
                            hide-details="auto"
                            dense
                          />
                        </div>
                      </div>

                      <!-- fields that has condition -->
                      <div>
                        <!-- for individual -->
                        <div>
                          <v-radio-group
                            v-if="
                              application_type_is_individual &&
                              question.type === 'single choice' &&
                              !question.is_a_follow_up_question &&
                              (question.title ===
                                'Are you borrowing money to lend via mortgages?' ||
                                question.title ===
                                  'If you invested into a mortgage, if you took a 100% loss, would you still be able to survive financially?' ||
                                question.title ===
                                  'Will you be lending money using personal funds?')
                            "
                            v-model="question.answer"
                            :rules="[rules.required]"
                            class="mb-5"
                            hide-details="auto"
                            dense
                          >
                            <v-radio
                              v-for="choice in question.frm_opts
                                ? question.frm_opts
                                : []"
                              :key="choice.id"
                              :label="choice.choice"
                              :value="choice.choice"
                            />
                          </v-radio-group>

                          <div
                            v-if="
                              application_type_is_individual &&
                              question.type === 'multiple choice' &&
                              !question.is_a_follow_up_question &&
                              question.title ===
                                'Individual Sophisticated Investor'
                            "
                            class="mb-5"
                          >
                            <v-checkbox
                              v-model="question.selected_choices"
                              v-for="choice in question.frm_opts
                                ? question.frm_opts
                                : []"
                              :key="choice.id"
                              :label="choice.choice"
                              :value="choice.id"
                              hide-details="auto"
                              dense
                            />
                          </div>
                        </div>

                        <!-- for corporation -->

                        <div>
                          <v-radio-group
                            v-if="
                              (!application_type_is_individual ||
                                application_type_is_also_corporation) &&
                              question.type === 'single choice' &&
                              !question.is_a_follow_up_question &&
                              question.title ===
                                'Will you be lending money out of a corporation you own / control?'
                            "
                            v-model="question.answer"
                            :rules="[rules.required]"
                            class="mb-5"
                            hide-details="auto"
                            dense
                          >
                            <v-radio
                              v-for="choice in question.frm_opts
                                ? question.frm_opts
                                : []"
                              :key="choice.id"
                              :label="choice.choice"
                              :value="choice.choice"
                            />
                          </v-radio-group>

                          <div
                            v-if="
                              (!application_type_is_individual ||
                                application_type_is_also_corporation) &&
                              question.type === 'multiple choice' &&
                              !question.is_a_follow_up_question &&
                              question.title ===
                                'Corporation Sophisticated Investor'
                            "
                          >
                            <v-checkbox
                              v-model="question.selected_choices"
                              v-for="choice in question.frm_opts
                                ? question.frm_opts
                                : []"
                              :key="choice.id"
                              :label="choice.choice"
                              :value="choice.id"
                              hide-details="auto"
                              dense
                            />
                          </div>
                        </div>
                      </div>

                      <!-- standard follow up questions -->
                      <div
                        class="pl-10"
                        v-if="
                          question.answer === 'Yes' &&
                          question.title !==
                            'Will you be lending money using personal funds?' &&
                          question.title !==
                            'Will you be lending money out of a corporation you own / control?'
                        "
                      >
                        <div v-for="fq1 in question.frm_qs" :key="fq1.id">
                          <p class="mb-0">
                            {{ fq1.title }}
                          </p>

                          <v-text-field
                            v-if="fq1.type === 'short text'"
                            v-model="fq1.answer"
                            :rules="[
                              fq1.title === 'Email'
                                ? rules.email
                                : rules.required,
                            ]"
                            class="mb-5"
                            hide-details="auto"
                            type="text"
                            dense
                            filled
                            outlined
                          />

                          <v-text-field
                            v-if="fq1.type === 'number'"
                            v-model="fq1.answer"
                            :rules="[
                              fq1.title === 'Phone Number'
                                ? rules.phone
                                : rules.required,
                            ]"
                            class="mb-5"
                            hide-details="auto"
                            type="number"
                            dense
                            filled
                            outlined
                          />

                          <v-text-field
                            v-if="fq1.type === 'date'"
                            v-model="fq1.answer"
                            :rules="[rules.required]"
                            class="mb-5"
                            hide-details="auto"
                            type="date"
                            dense
                            filled
                            outlined
                          />

                          <v-textarea
                            v-if="fq1.type === 'long text'"
                            v-model="fq1.answer"
                            :rules="[rules.required]"
                            class="mb-5"
                            hide-details="auto"
                            type="text"
                            auto-grow
                            dense
                            filled
                            outlined
                          />

                          <v-radio-group
                            v-if="fq1.type === 'single choice'"
                            v-model="fq1.answer"
                            :rules="[rules.required]"
                            class="mb-5"
                            hide-details="auto"
                            dense
                          >
                            <v-radio
                              v-for="choice in fq1.frm_opts ? fq1.frm_opts : []"
                              :key="choice.id"
                              :label="choice.choice"
                              :value="choice.choice"
                            />
                          </v-radio-group>
                        </div>
                      </div>

                      <!-- follow up questions for accounts and source of funds-->
                      <div
                        class="pl-10"
                        v-if="
                          question.answer === 'Yes' &&
                          (question.title ===
                            'Will you be lending money using personal funds?' ||
                            question.title ===
                              'Will you be lending money out of a corporation you own / control?')
                        "
                      >
                        <!-- follow up question for individual -->
                        <div
                          v-if="
                            application_type_is_individual &&
                            question.title ===
                              'Will you be lending money using personal funds?'
                          "
                        >
                          <p>Please add your personal fund:</p>

                          <!-- show added personal funds -->
                          <div
                            v-for="(fq1, fq1_index) in question.frm_qs"
                            :key="fq1_index"
                            class="mt-5"
                          >
                            <v-row
                              class="pa-2 ma-2 rounded-lg"
                              style="background-color: #111"
                            >
                              <v-col>
                                <p class="mb-0">Type: {{ fq1.answer }}</p>

                                <p
                                  v-for="fq2 in fq1.frm_qs"
                                  :key="fq2.order"
                                  class="mb-0"
                                >
                                  {{
                                    fq1.answer === "RRSP/TFSA"
                                      ? "Name of Trust Company: "
                                      : ""
                                  }}

                                  {{
                                    fq1.answer === "Others"
                                      ? "Specification: "
                                      : ""
                                  }}

                                  {{ fq2.answer }}
                                </p>
                              </v-col>

                              <v-col class="d-flex justify-end align-center">
                                <v-btn
                                  icon
                                  color="red"
                                  title="Delete"
                                  @click="question.frm_qs.splice(fq1_index, 1)"
                                >
                                  <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>

                          <div class="mb-5">
                            <div v-if="adding_personal_source_of_fund">
                              <v-select
                                v-model="personal_source_of_fund.answer"
                                :items="personal_source_of_fund.frm_opts"
                                class="mb-5"
                                item-text="choice"
                                item-value="choice"
                                label="Select type:"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />

                              <v-text-field
                                v-if="
                                  personal_source_of_fund.answer === 'RRSP/TFSA'
                                "
                                v-model="
                                  personal_source_of_fund.follow_up_question_answer
                                "
                                class="mb-5"
                                label="Name of Trust Company"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />

                              <v-text-field
                                v-if="
                                  personal_source_of_fund.answer === 'Others'
                                "
                                v-model="
                                  personal_source_of_fund.follow_up_question_answer
                                "
                                class="mb-5"
                                label="Please Specify"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />
                            </div>

                            <v-btn
                              class="mr-5"
                              @click="
                                adding_personal_source_of_fund =
                                  !adding_personal_source_of_fund
                              "
                            >
                              {{
                                adding_personal_source_of_fund
                                  ? "Cancel"
                                  : "Add"
                              }}
                            </v-btn>

                            <v-btn
                              v-if="adding_personal_source_of_fund"
                              class="mr-5"
                              @click="
                                handleAddIndividualAccount(
                                  section.id,
                                  question.id
                                )
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </div>

                        <!-- follow up question for corporation -->
                        <div
                          v-if="
                            (!application_type_is_individual ||
                              application_type_is_also_corporation) &&
                            question.title ===
                              'Will you be lending money out of a corporation you own / control?'
                          "
                        >
                          <div>
                            <p>Please add the corporation you own / control</p>

                            <div
                              v-for="(fq1, fq1_index) in question.frm_qs"
                              :key="fq1_index"
                              class="mt-5"
                            >
                              <v-row
                                class="pa-2 ma-2 rounded-lg"
                                style="background-color: #111"
                              >
                                <v-col>
                                  <p
                                    v-for="fq2 in fq1.frm_qs"
                                    :key="fq2.order"
                                    class="mb-0"
                                  >
                                    {{ fq2.title }} :
                                    <span class="grey--text"
                                      >{{ fq2.answer }}
                                    </span>

                                    <span
                                      v-for="fq3 in fq2.frm_qs"
                                      :key="fq3.order"
                                      class="mb-0"
                                    >
                                      {{ fq3.title }} :
                                      <span class="grey--text"
                                        >{{ fq3.answer }}
                                      </span>
                                    </span>
                                  </p>
                                </v-col>

                                <v-col class="d-flex justify-end align-start">
                                  <v-btn
                                    icon
                                    color="red"
                                    title="Delete"
                                    @click="
                                      question.frm_qs.splice(fq1_index, 1)
                                    "
                                  >
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </div>

                            <div
                              v-if="adding_corporation_detail"
                              class="px-5 pt-6 mt-5 rounded-lg"
                              style="border: 1px grey solid"
                            >
                              <v-text-field
                                v-model="corporation_detail.name"
                                class="mb-5"
                                label="Name"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />

                              <v-textarea
                                v-model="corporation_detail.address"
                                class="mb-5"
                                label="Address"
                                hide-details="auto"
                                type="text"
                                auto-grow
                                dense
                                filled
                                outlined
                              />

                              <v-text-field
                                v-model="corporation_detail.date_of_formation"
                                class="mb-5"
                                label="Date of formation"
                                hide-details="auto"
                                type="date"
                                dense
                                filled
                                outlined
                              />

                              <v-text-field
                                v-model="corporation_detail.shareholders"
                                class="mb-5"
                                label="Shareholders directors offiers partners"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />

                              <v-select
                                v-model="corporation_detail.type"
                                :items="corporation_detail.frm_opts"
                                class="mb-5"
                                item-text="choice"
                                item-value="choice"
                                label="Type"
                                hide-details="auto"
                                dense
                                filled
                                outlined
                              />

                              <v-text-field
                                v-if="corporation_detail.type === 'Other'"
                                v-model="corporation_detail.specify_type"
                                class="mb-5 ml-5"
                                label="Please specify"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />

                              <v-text-field
                                v-model="corporation_detail.corporate_structure"
                                class="mb-5"
                                label="What is the corporate structure"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />

                              <v-text-field
                                v-model="corporation_detail.nature_or_purpose"
                                class="mb-5"
                                label="What is the nature or purpose of corporation"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />

                              <v-text-field
                                v-model="corporation_detail.source_of_funds"
                                class="mb-5"
                                label="What is source of funds"
                                hide-details="auto"
                                type="text"
                                dense
                                filled
                                outlined
                              />
                            </div>

                            <v-btn
                              class="mt-5 mr-5"
                              @click="
                                adding_corporation_detail =
                                  !adding_corporation_detail
                              "
                            >
                              {{ adding_corporation_detail ? "Cancel" : "Add" }}
                            </v-btn>

                            <v-btn
                              v-if="adding_corporation_detail"
                              class="mt-5 mr-5"
                              @click="
                                handleAddCorporationAccount(
                                  section.id,
                                  question.id
                                )
                              "
                            >
                              save
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-form>
                </v-card>

                <v-btn
                  v-if="filtered_sections.length !== index + 1"
                  color="primary"
                  :disabled="!section.is_valid"
                  @click="step = filtered_sections[index + 1].title"
                >
                  Continue
                </v-btn>

                <v-btn
                  v-if="filtered_sections.length === index + 1"
                  color="primary"
                  :disabled="!section.is_valid || formIsSubmitting"
                  @click="handleSubmit"
                >
                  Submit
                  <v-progress-circular
                    v-show="formIsSubmitting"
                    class="mr-2"
                    color="primary"
                    indeterminate
                  />
                </v-btn>

                <v-btn
                  text
                  @click="
                    index > 0
                      ? (step = filtered_sections[index - 1].title)
                      : $router.go(-1)
                  "
                >
                  {{ index === 0 ? "Cancel" : "Back" }}
                </v-btn>
              </v-stepper-content>
            </div>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "Investor_KYC_Form",

  props: [
    "form_unique_id",
    "company_id",
    "user_id",
    "view_type",
    "form_access_token",
  ],

  data: () => ({
    selected_application_type: "",
    application_type_is_individual: null,
    formIsError: false,
    formIsLoading: true,
    formIsSubmitting: false,
    formIsSubmitted: false,
    viewable: false,

    step: "Initial Information",

    // for both personal and corporation
    application_type_is_also_corporation: false,

    // for personal funds
    adding_personal_source_of_fund: false,

    personal_source_of_fund: {
      answer: "",
      follow_up_question_answer: "",

      frm_opts: [
        { order: 1, choice: "Cash" },
        { order: 2, choice: "RRSP/TFSA" },
        { order: 3, choice: "Others" },
      ],
    },

    // for corporation funds
    adding_corporation_detail: false,

    corporation_detail: {
      name: "",
      address: "",
      date_of_formation: "",
      shareholders: "",
      type: "",
      specify_type: "",
      corporate_structure: "",
      nature_or_purpose: "",
      source_of_funds: "",

      frm_opts: [
        { order: 1, choice: "MIC" },
        { order: 2, choice: "MIE" },
        {
          order: 3,
          choice: "Brokerage",
        },
        { order: 4, choice: "Other" },
      ],
    },

    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      phone: (value) =>
        (value && value.length == 10) ||
        "Phone number must be 10 digits no spaces or characters (format 7803334444)",
    },

    formData: {
      title: "Investor KYC",
      description: "",
      unique_id: "investor-kyc-2023-v1",

      frm_sects: [
        {
          order: 1,
          title: "Initial Information",
          description: "",
          is_valid: false,

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title: "Name",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title: "Email",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              answer: "",
              title: "Phone Number",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              answer: "",
              title: "Application type",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  id: 1,
                  order: 1,
                  choice:
                    "I am an individual who invests in Mortgages with only personal cash, RRSP/TFSA",
                },
                {
                  id: 2,
                  order: 2,
                  choice:
                    "I am an individual who invests in mortgages with cash, RRSP/TFSA, AND own/control corporations that invest my money",
                },
                {
                  id: 3,
                  order: 3,
                  choice:
                    "I am applying on behalf of a corporation that is primarily in the business of lending money via mortgages",
                },
                {
                  id: 4,
                  order: 4,
                  choice:
                    "Mortgage brokerage, Mortgage Investment Company, Mortgage Investment Entity, Etc.",
                },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 2,
          title: "Investment Knowledge",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title: "What is your level of mortgage investment knowledge?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Novice" },
                { order: 2, choice: "Fair" },
                { order: 3, choice: "Good" },
                { order: 4, choice: "Sophisticated" },
              ],

              frm_qs: [],
            },

            {
              id: 2,
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title: "Have you invested in mortgages before?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Yes" },
                { id: 2, order: 2, choice: "No" },
              ],

              frm_qs: [
                {
                  id: 1,
                  order: 1,
                  is_a_follow_up_question: true,
                  answer: "",
                  title: "How many years have you been investing in mortgages?",
                  description: "",
                  type: "number",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },

                {
                  id: 2,
                  order: 2,
                  is_a_follow_up_question: true,
                  answer: "",
                  title: "How many mortgages have you invested in?",
                  description: "",
                  type: "number",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },

                {
                  id: 3,
                  order: 3,
                  is_a_follow_up_question: true,
                  answer: "",
                  title:
                    "Approximately how much funds do you have invested in mortgages currently?",
                  description: "",
                  type: "number",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },

                {
                  id: 4,
                  order: 4,
                  is_a_follow_up_question: true,
                  answer: "",
                  title: "Have you ever had a mortgage go into foreclosure?",
                  description: "",
                  type: "single choice",
                  selected_choices: [],

                  frm_opts: [
                    { id: 1, order: 1, choice: "Yes" },
                    { id: 2, order: 2, choice: "No" },
                  ],

                  frm_qs: [],
                },
              ],
            },

            {
              id: 3,
              order: 3,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "Are you aware how fluctuations in housing market conditions can affect your security with losing some or all your investment?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Yes" },
                { id: 2, order: 2, choice: "No" },
              ],

              frm_qs: [],
            },

            {
              id: 4,
              order: 4,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "The ability of the investor/lender to comfortably withstand a delay in the return of the principal/capital invested in the non-qualified syndicated mortgage given his/her current financial circumstances (e.g., tolerance for delay)",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Yes" },
                { id: 2, order: 2, choice: "No" },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 3,
          title: "Sophisticated Declaration",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title: "Individual Sophisticated Investor",
              description: "",
              type: "multiple choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice:
                    "A person or entity who is registered as an adviser or dealer under the Securities Act or another similar legistlation in Canada",
                },
                {
                  order: 2,
                  choice:
                    "An individual who, alone or together with his or her spouse, has net assets of at least $5 million and who provides written confirmation of this to the brokerage.",
                },
                {
                  order: 3,
                  choice:
                    "An individual who, alone or together with his or her spouse, beneficially owns financial assets that have an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $1 million and who provides written confirmation of this to the brokerage.",
                },
                {
                  order: 4,
                  choice:
                    "An individual whose net income before taxes in each of the two most recent years exceeded $200,000 or whose net income before taxes in each of those years combined with that of his or her spouse in each of those years exceeded $300,000, who has a reasonable expectation of exceeding the same net income or combined net income, as the case may be, in the current year and who provides written confirmation of this to the brokerage.",
                },
              ],

              frm_qs: [],
            },

            {
              id: 2,
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title: "Corporation Sophisticated Investor",
              description: "",
              type: "multiple choice",
              selected_choices: [],

              frm_opts: [
                {
                  id: 1,
                  order: 1,
                  choice:
                    "A person or entity, other than an individual, who has net assets of at least $5 million as reflected in its most recently-prepared financial statements and who provides written confirmation of this to the brokerage.",
                },
                {
                  id: 2,
                  order: 2,
                  choice:
                    "A person or entity who is registered as an adviser or dealer under the Securities Act.",
                },
                {
                  id: 3,
                  order: 3,
                  choice:
                    "A person or entity who is registered under securities legislation in another province or territory of Canada.",
                },
                {
                  id: 4,
                  order: 4,
                  choice: "A brokerage acting on its own behalf",
                },
                {
                  id: 5,
                  order: 5,
                  choice: "A financial institution.",
                },
                {
                  id: 6,
                  order: 6,
                  choice:
                    "A corporation that is a subsidiary of a person or entity of a Crown entity, a brokerage, or a financial institution.",
                },
                {
                  id: 7,
                  order: 7,
                  choice:
                    "A corporation that is an approved lender under the National Housing Act (Canada).",
                },
                {
                  id: 8,
                  order: 8,
                  choice:
                    "The Crown in right of Ontario, Canada or any province or territory of Canada.",
                },
                {
                  id: 9,
                  order: 9,
                  choice:
                    "An administrator or trustee of a registered pension plan",
                },
                {
                  id: 10,
                  order: 10,
                  choice:
                    "A person or entity in respect of which all of the owners of interests, other than the owners of voting securities required by law to be owned by directors, are persons or entities described above.",
                },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 4,
          title: "Financial Situation",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title: "What is your net personal income for last two years?",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "What is your net income for last two years (each year) combined with spouse?",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              answer: "",
              title: "What is your approximate net worth?",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              answer: "",
              title: "Does investor understand costs during defaults?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Yes" },
                { id: 2, order: 2, choice: "No" },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 5,
          title: "Liquidity",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "Do you need all your invested money back in the next year or two?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title: "What is your liquidity requirement in years?",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              answer: "",
              title: "What is your investment objective?",
              description: "",
              type: "multiple choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Safety" },
                { id: 2, order: 2, choice: "Income" },
                { id: 3, order: 3, choice: "Balance" },
                { id: 4, order: 4, choice: "Growth" },
                { id: 5, order: 5, choice: "Aggressive" },
                { id: 6, order: 6, choice: "Speculation preserve capital" },
                { id: 7, order: 7, choice: "Generate income" },
                { id: 8, order: 8, choice: "Generate income and growth" },
                {
                  id: 9,
                  order: 9,
                  choice:
                    "Generate maximum investment return regardless of higher risk",
                },
              ],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              answer: "",
              title: "Are you borrowing money to lend via mortgages?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Yes" },
                { id: 2, order: 2, choice: "No" },
              ],

              frm_qs: [],
            },

            {
              order: 5,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "If you invested into a mortgage, if you took a 100% loss, would you still be able to survive financially?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Yes" },
                { id: 2, order: 2, choice: "No" },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 6,
          title: "Risk/LTV",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "What is your risk tolerance for mortgages? Include examples of LTV in rural and urban. (Please enter N/A if not applicable)",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title: "Urban - House",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              answer: "",
              title: "Urban - Condo",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              answer: "",
              title: "Urban - Bare Land",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 5,
              is_a_follow_up_question: false,
              answer: "",
              title: "Rural - House",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 6,
              is_a_follow_up_question: false,
              answer: "",
              title: "Rural - Condo",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 7,
              is_a_follow_up_question: false,
              answer: "",
              title: "Rural - Bare Land",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 8,
              is_a_follow_up_question: false,
              answer: "",
              title: "What are your investment goals?",
              description: "",
              type: "long text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
          ],
        },

        {
          id: 7,
          order: 7,
          title: "Broker Declaration",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              id: 1,
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "Are you currently or formerly licensed as a mortgage broker in any Canadian province?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Yes" },
                { id: 2, order: 2, choice: "No" },
              ],

              frm_qs: [
                {
                  id: 1,
                  order: 1,
                  is_a_follow_up_question: true,
                  answer: "",
                  title: "Please indicate which province",
                  description: "",
                  type: "short text",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },
              ],
            },

            {
              id: 2,
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "Are you pooling together money from other people to invest in mortgages?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { id: 1, order: 1, choice: "Yes" },
                { id: 2, order: 2, choice: "No" },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 8,
          title: "Accounts and Source of Funds",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title: "Will you be lending money using personal funds?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "Will you be lending money out of a corporation you own / control?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 9,
          title: "Personal/Contact info",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              answer: "",
              title: "Address",
              description: "",
              type: "long text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title: "Birthday",
              description: "",
              type: "date",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              answer: "",
              title: "Marital Status",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              answer: "",
              title: "Number of dependents",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 5,
              is_a_follow_up_question: false,
              answer: "",
              title: "Occupation",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 6,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "Emergency Contact Information (In case of death or illness to handle mortgage affairs)",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 7,
              is_a_follow_up_question: false,
              answer: "",
              title: "Name",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 8,
              is_a_follow_up_question: false,
              answer: "",
              title: "Phone Number",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 9,
              is_a_follow_up_question: false,
              answer: "",
              title: "Email",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 10,
              is_a_follow_up_question: false,
              answer: "",
              title: "Relationship",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
          ],
        },
      ],
    },
  }),

  computed: {
    filtered_sections() {
      // check sophisticated declaration if there are selected choices
      const sophisticated_declaration = this.formData.frm_sects.find(
        (section) => section.title === "Sophisticated Declaration"
      );
      const it_has_selected_sophisticated_declaration =
        sophisticated_declaration.frm_qs.some(
          (question) => question.selected_choices.length > 0
        );

      const result = this.formData.frm_sects
        .map((section) => {
          if (
            this.selected_application_type ===
              "I am applying on behalf of a corporation that is primarily in the business of lending money via mortgages" ||
            this.selected_application_type ===
              "Mortgage brokerage, Mortgage Investment Company, Mortgage Investment Entity, Etc."
          ) {
            if (
              section.title === "Investment Knowledge" ||
              section.title === "Broker Declaration" ||
              section.title === "Liquidity"
            ) {
              return null;
            }
          }

          if (
            it_has_selected_sophisticated_declaration &&
            section.title === "Financial Situation"
          ) {
            return null;
          }

          return section;
        })
        .filter(Boolean);

      return result;
    },
  },

  methods: {
    it_has_selected_sophisticated_declaration() {
      const sophisticated_declaration = this.formData.frm_sects.find(
        (section) => section.title === "Sophisticated Declaration"
      );
      const it_has_selected_sophisticated_declaration =
        sophisticated_declaration.frm_qs.some(
          (question) => question.selected_choices.length > 0
        );

      return it_has_selected_sophisticated_declaration;
    },

    handleApplicationType(answer) {
      this.selected_application_type = answer;

      if (
        answer ===
        "I am an individual who invests in Mortgages with only personal cash, RRSP/TFSA"
      ) {
        this.application_type_is_individual = true;
        this.application_type_is_also_corporation = false;
      } else if (
        answer ===
        "I am an individual who invests in mortgages with cash, RRSP/TFSA, AND own/control corporations that invest my money"
      ) {
        this.application_type_is_individual = true;
        this.application_type_is_also_corporation = true;
      } else this.application_type_is_individual = false;

      console.log(
        `Are you an individual? ${this.application_type_is_individual} - ${this.selected_application_type}`
      );

      // clear if there are selected choices in sophisticated declaration
      const section = this.formData.frm_sects.find(
        (section) => section.title === "Sophisticated Declaration"
      );

      const individual_declaration = section.frm_qs.find(
        (question) => question.title === "Individual Sophisticated Investor"
      );

      const corporation_declaration = section.frm_qs.find(
        (question) => question.title === "Corporation Sophisticated Investor"
      );

      if (individual_declaration.selected_choices.length > 0) {
        individual_declaration.selected_choices = [];
      }

      if (corporation_declaration.selected_choices.length > 0) {
        corporation_declaration.selected_choices = [];
      }
    },

    handleAddIndividualAccount(section_id, question_id) {
      const found_section = this.formData.frm_sects.find(
        (section) => section.id === section_id
      );

      const found_question = found_section.frm_qs.find(
        (question) => question.id === question_id
      );

      found_question.frm_qs.push({
        id: question_id,
        order: found_question.frm_qs.length + 1,
        answer: this.personal_source_of_fund.answer,
        type: "select choice",

        frm_qs: [
          {
            id: question_id,
            order: 1,
            answer: this.personal_source_of_fund.follow_up_question_answer,
            type: "short text",

            frm_qs: [],
          },
        ],
      });

      console.log("question: ", found_question);

      this.adding_personal_source_of_fund = false;
      this.personal_source_of_fund.answer = "";
      this.personal_source_of_fund.follow_up_question_answer = "";
    },

    handleAddCorporationAccount(section_id, question_id) {
      const found_section = this.formData.frm_sects.find(
        (section) => section.id === section_id
      );

      const found_question = found_section.frm_qs.find(
        (question) => question.id === question_id
      );

      found_question.frm_qs.push({
        id: question_id,
        order: found_question.frm_qs.length + 1,
        is_a_follow_up_question: true,
        answer: "",
        title: "Corporation details",
        description: "",
        type: null,
        selected_choices: [],

        frm_opts: [],

        frm_qs: [],
      });

      // get the last index of the array
      const last_index =
        found_question.frm_qs.length > 0 ? found_question.frm_qs.length - 1 : 0;

      found_question.frm_qs.forEach((el, index) => {
        if (index === last_index) {
          // push the follow up question to the last index of the array
          found_question.frm_qs[last_index].frm_qs.push(
            {
              id: question_id,
              order: 1,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.name,
              title: "Name",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
            {
              id: question_id,
              order: 2,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.address,
              title: "Address",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
            {
              id: question_id,
              order: 3,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.date_of_formation,
              title: "Date of formation",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
            {
              id: question_id,
              order: 4,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.shareholders,
              title: "Shareholders directors offiers partners",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
            {
              id: question_id,
              order: 5,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.type,
              title: "Type",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              id: question_id,
              order: 6,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.specify_type,
              title: "Type specification",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              id: question_id,
              order: 7,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.corporate_structure,
              title: "What is the corporate structure",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
            {
              id: question_id,
              order: 8,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.nature_or_purpose,
              title: "What is the nature or purpose of corporation",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
            {
              id: question_id,
              order: 9,
              is_a_follow_up_question: true,
              answer: this.corporation_detail.source_of_funds,
              title: "What is source of funds",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            }
          );
        }
      });

      this.corporation_detail.name = "";
      this.corporation_detail.address = "";
      this.corporation_detail.date_of_formation = "";
      this.corporation_detail.shareholders = "";
      this.corporation_detail.type = "";
      this.corporation_detail.specify_type = "";
      this.corporation_detail.corporate_structure = "";
      this.corporation_detail.nature_or_purpose = "";
      this.corporation_detail.source_of_funds = "";

      this.adding_corporation_detail = false;
    },

    async handleSubmit() {
      try {
        this.formIsSubmitting = true;
        console.log(
          "This formData should be sending to the backend: ",
          this.formData
        );

        this.formDataIsSubmitting = false;
        this.formIsSubmitted = true;

        setTimeout(() => {
          this.formIsSubmitted = false;
          if(this.view_type === "logged_in") this.$router.go(-1);
          if(this.view_type === "form_access_link") this.$router.push("/");
        }, 2000);
        
        if (this.view_type === "logged_in") {
          const response = await API().post(
            `api/form/create_investor_kyc_response`,
            {
              form_data: this.formData,
              company_id: this.company_id,
              user_id: this.user_id,
              unique_id: this.form_unique_id,
            }
          );

          console.log(response);
        }

        if (this.view_type === "form_access_link") {
          const response = await API().post(
            `api/form-access-link/create_investor_kyc_response`,
            {
              form_data: this.formData,
              company_id: this.company_id,
              user_id: this.user_id,
              unique_id: this.form_unique_id,
            }
          );

          console.log(response);
        }
      } catch (error) {
        console.log("error in submitting form data: ", error);
        this.formIsError = true;

        setTimeout(() => {
          this.formIsError = false;
        }, 2000);
      }
    },

    async getFormData() {
      try {
        if (this.view_type === "logged_in") {
          const response = await API().get(
            `api/form/read_form_data?unique_id=${this.form_unique_id}`
          );
          if (response.status === 200) {
            this.formData = response.data;
            this.viewable = true;
            console.log("form data: ", this.formData);
          }
          if (response.status === 204) {
            const new_form = await API().post(`api/form/create_form_data`, {
              form_data: this.formData,
            });
            this.formData = new_form.data;
            this.viewable = true;
            console.log("new form data: ", this.formData);
          }

          this.formIsLoading = false;
        }

        if (this.view_type === "form_access_link") {
          console.log("form link token: ", this.form_access_token);
          const response = await API().get(
            `api/form-access-link/read_form_data?form_unique_id=${this.form_unique_id}&token=${this.form_access_token}`
          );

          if (response.status === 200) {
            this.formData = response.data;
            this.viewable = true;
          }

          if (response.status === 204) {
            this.viewable = false;
          }

          console.log(this.formData);

          this.formIsLoading = false;
        }
      } catch (error) {
        console.log("error in getting form data: ", error);
        this.formIsError = true;

        setTimeout(() => {
          this.formIsError = false;
        }, 2000);
      }
    },
  },

  mounted() {
    this.getFormData();
  },
};
</script>
