<template>
  <div>
    <Investor_KYC_Form
      :company_id="company_id"
      :user_id="user_id"
      :form_access_token="$route.query.form_token"
      :form_unique_id="form_unique_id"
      :view_type="view_type"
      v-if="form_unique_id === 'investor-kyc-2023-v1'"
    />
  </div>
</template>

<script>
import Investor_KYC_Form from "@/components/Investor_KYC_Form"; 

export default {
  components: {
    Investor_KYC_Form,
  },

  props: ["form_unique_id", "company_id", "user_id"],

  data: () => ({
    view_type: "form_access_link",
    form_token: "",
    viewable: false,
  }),

  mounted() {
    console.log("token: ", this.$route.query.form_token);
    console.log("form_unique_id: ", this.form_unique_id);
    console.log("company_id: ", this.id);
  },
};
</script>
